import Utils from './Utils';
import LoopScroll from './LoopScroll';

export default class Scene {
    constructor(audio) {
        this.data;
        this.images = [];
        this.siteTitle = document.getElementById('siteTitle');
        this.credits = document.getElementById('credits');
        this.container = document.getElementById('imagesContainer');
        this.lineContainer = document.getElementById('lineContainer');
        this.loaded = false;
        this.clones = [];
        this.numClones = 5;
        this.audio = audio;

        this.dots = window.setInterval(() => {
            this.wait = document.querySelector('.loader');
            if (this.wait.innerHTML.length > 3) this.wait.innerHTML = '';
            else this.wait.innerHTML += '.';
        }, 300);
    }

    init() {
        Utils.shuffle(this.data);
        this.numImages = this.data.length;
        this.data.forEach((el, i) => {
            if (i <= 4) {
                this.clones.push(el);
            }
            //   console.log(el);
            let downloadingImage = new Image();
            downloadingImage.src = './assets/images/' + el.name;

            // downloadingImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
            // let div = document.createElement("div");
            // div.style.width = el.width + 'px';
            // div.style.flex = '0 0 '+ el.width + 'px';
            // div.style.height = el.height + 'px';
            // div.classList.add('nl-div')

            downloadingImage.onload = () => {
                let image = document.createElement('img');
                image.width = el.width;
                image.height = el.height;
                // image.src = downloadingImage.src;
                image.dataset.src = './assets/images/' + el.name;
                // image.src = downloadingImage.src;
                image.src =
                    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
                image.classList.add('nl-img');
                image.style.transform = `rotate(${el.rotation}deg) translateY(${el.y}px)`;
                // div.appendChild(image);
                this.lineContainer.appendChild(image);
                this.numImages--;
                if (this.numImages == 0) {
                    // this.loaded = true;
                    let credits = document.createElement('p');
                    credits.innerHTML = this.credits.innerHTML;
                    this.container.appendChild(credits);
                    let title = document.createElement('h1');
                    title.innerHTML = this.siteTitle.innerHTML;
                    title.classList.add('is-clone');
                    this.container.appendChild(title);

                    setTimeout(() => {
                        this.loopScroll = new LoopScroll(
                            false,
                            this.container,
                            this.audio,
                            this.data,
                        );

                        this.loopScroll.init();
                        this.loaded = true;
                        document.body.classList.remove('loading');
                        window.clearInterval(this.dots);
                        this.wait.innerHTML = '';
                        this.waitClone =
                            document.querySelector('.is-clone .loader');
                        this.waitClone.innerHTML = '';
                    }, 1000);
                    // this.clones.forEach((el, i) => {
                    //   //   console.log(el);
                    //   let downloadingImage = new Image();
                    //   downloadingImage.src = "./assets/images/" + el.name;
                    //   // downloadingImage.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
                    //   // let div = document.createElement('div');
                    //   // div.style.width = el.width + 'px';
                    //   // div.style.flex = '0 0 '+ el.width + 'px';
                    //   // div.style.height = el.height + 'px';
                    //   // div.classList.add('nl-div');
                    //   // div.classList.add('is-clone');
                    //   let image = document.createElement("img");
                    //   image.width = el.width;
                    //   image.height = el.height;
                    //   downloadingImage.onload = () => {
                    //     image.src = downloadingImage.src;
                    //     image.classList.add("nl-img");
                    //     image.classList.add("is-clone");
                    //     image.style.transform = `rotate(${el.rotation}deg) translateY(${el.y}px)`;
                    //     // div.appendChild(image);
                    //     this.container.appendChild(image);
                    //     this.numClones--;
                    //     console.log(this.numClones);
                    //     if (this.numClones == 0) {
                    //       setTimeout(() => {
                    //         this.loopScroll = new LoopScroll(false, this.container, this.audio);

                    //         this.loopScroll.init();
                    //         this.loaded = true;
                    //       }, 1000);
                    //     }
                    //   };
                    // });
                }
            };
        });
    }

    // init() {
    // 	Utils.shuffle(this.data);
    // 	this.numImages = this.data.length;
    // 	this.data.forEach((el, i) => {
    // 		let downloadingImage = new Image();
    // 		downloadingImage.src = './assets/images/' + el.name;
    // 		downloadingImage.onload = () => {
    // 			this.numImages--;
    // 			if (this.numImages == 0) {

    // 				setTimeout(() => {
    // 					this.loopScroll = new LoopScroll(
    // 						false,
    // 						this.container,
    // 						this.audio,
    // 						this.data
    // 					);

    // 					this.loopScroll.init();
    // 					this.loaded = true;
    // 				}, 1000);
    // 			}
    // 		};
    // 	});
    // }

    animate() {
        if (this.loaded) {
            this.loopScroll.animate();
        }
    }

    onWindewResize() {
        this.loopScroll.onWindewResize();
    }

    loadJson() {
        fetch('../assets/netherlandsline-new.json')
            .then((res) => res.json())
            .then((data) => {
                this.data = data;
                this.init();
            });
    }
}
