'use strict';
// import MobileDetect from "mobile-detect";
// import gsap from 'gsap'
import Scene from './Scene';
import Audio from './Audio';

export default class App {
    constructor() {
        // this.md = new MobileDetect(window.navigator.userAgent);
        // this.isMobile = this.md.mobile();

        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
        this.container = document.getElementById('imagesContainer');
    }

    run() {
        // if (this.isMobile) {
        //   document.body.classList.add("mobile");
        // }
        this.audio = new Audio(false, this.container);
        this.scene = new Scene(this.audio);
        this.scene.loadJson();
        this.audio.init();

        requestAnimationFrame(this.animate.bind(this));
        // gsap.ticker.add(this.animate.bind(this))
        window.addEventListener(
            'resize',
            this.onWindowResize.bind(this),
            false,
        );
        window.addEventListener('load', this.onWindowLoad.bind(this), false);
    }

    animate() {
        this.scene.animate();
        requestAnimationFrame(this.animate.bind(this));
    }

    onWindowResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        this.scene.onWindewResize();
    }

    onWindowLoad() {}
}

let app = new App();

if (document.readyState === 'complete') {
    app.run();
} else {
    document.addEventListener(
        'DOMContentLoaded',
        () => {
            app.run();
        },
        false,
    );
}
