import { Howl, Howler } from 'howler';
export default class Audio {
    constructor(isMobile, elem) {
        this.context = elem;
        this.isMobile = isMobile;

        this.firstClick = false;
        this.sound = new Howl({
            src: ['./assets/audio/sea.mp3'],
            autoplay: true,
            loop: true,
            volume: 0.5,
            onend: function () {
                // console.log('Finished!');
            },
            onplayerror: () => {
                // this.context.classList.add('muted');
                // document.body.classList.add('muted');
                this.sound.once('unlock', () => {
                    this.sound.play();
                });
            },
            onplay: () => {
                // this.context.classList.remove('muted');
                this.firstClick = true;
                document.body.classList.remove('muted');
            },
        });
    }

    init() {
        this.context.addEventListener('click', (e) => {
            this.firstClick = true;
            if (!this.firstClick) {
                this.context.classList.remove('muted');
                this.sound.play();
            }
        });

        document.querySelector('.mute').addEventListener('click', () => {
            console.log('click');
            document.body.classList.add('muted');
            this.sound.mute(true);
        });

        document.querySelector('.unmute').addEventListener('click', () => {
            console.log('click');
            document.body.classList.remove('muted');
            this.sound.play();
            this.sound.mute(false);
        });
    }

    animate(rate) {
        this.sound.rate(rate);
    }
}
