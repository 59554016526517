import Utils from './Utils';
export default class LoopScroll {
    constructor(isMobile, elem, audio) {
        this.container = document.getElementById('imagesContainer');
        this.siteTitle = document.getElementById('siteTitle');
        this.siteTitleClone = document.querySelector('h1.is-clone');
        this.credits = document.getElementById('credits');

        this.context = elem;
        // this.container = this.doc.querySelector('.imageContainer');
        this.clones = this.context.querySelectorAll('.is-clone');
        this.disableScroll = false;
        this.scrollWidth = 0;
        this.scrollPos = 0;
        this.clonesWidth = 0;
        this.i = 0;
        this.scrolling = false;
        // this.imagContainers = this.doc.querySelectorAll('.imageContainer > div');
        // this.lastImages = this.doc.querySelectorAll('.siteContainer img:last-child');
        // this.labels = this.doc.querySelectorAll('.label');
        // this.numImages = this.imagContainers.length;
        this.scrollLeft = 0;
        this.deltaY = 0;
        this.deltaX = 0;
        this.delta = 0;
        this.deltaSmooth = 0;
        this.disableAutomatic = false;
        this.mouseDown = false;
        this.mouseStill = false;
        this.frame = 0;
        this.isMobile = isMobile;
        this.mouseover = false;
        // console.log(this.clones);

        this.rate = 0;

        this.audio = audio;

        this.lineContainer = document.getElementById('lineContainer');
        this.lineWidth = lineContainer.clientWidth;
        this.lineContainer.style.width = this.lineWidth + 'px';

        this.toAbout = document.getElementById('to-about');

        // this.speedSlider = document.getElementById('speed-slider')
        // this.speedText = document.getElementById('speed-text');
        // this.scrollingSpeed = this.speedSlider.value
    }

    getScrollPos() {
        return this.context.scrollLeft;
    }
    setScrollPos(pos) {
        this.scrollLeft = pos;
        this.context.scrollLeft = pos;
        // this.context.scroll(pos, 0);
    }

    getClonesWidth() {
        this.clonesWidth = 0;

        for (this.i = 0; this.i < this.clones.length; this.i += 1) {
            this.clonesWidth += this.clones[this.i].clientWidth;
        }

        return this.clonesWidth;
    }

    reCalc() {
        this.scrollPos = this.getScrollPos();
        this.scrollWidth = this.context.scrollWidth;
        this.clonesWidth = this.getClonesWidth();

        if (this.scrollPos <= 0) {
            this.setScrollPos(0);
        }
    }

    mouseWheel(e) {
        this.deltaY = e.deltaY;
        this.scrollLeft += parseFloat(this.deltaY);
        this.context.scrollLeft = this.scrollLeft;
        if (this.deltaY > 0) {
            this.rate = Utils.mapRange(this.deltaY, 1, 150, 0, 3);
        } else {
            this.rate = Utils.mapRange(this.deltaY, 1, 150, 0, 0.8);
        }
        // console.log(rate)
        // this.context.scrollLeft += this.deltaY;
    }

    touchMove(e) {
        // console.log(e)
        // this.deltaY = e.deltaY;
        // this.scrollLeft += parseFloat(this.deltaY);
        // this.context.scrollLeft = this.scrollLeft;
        this.scrollLeft = this.context.scrollLeft;
        // if (this.deltaY > 0) {
        // 	this.rate = Utils.mapRange(this.deltaY, 1, 150, 0, 3);
        // } else {
        // 	this.rate = Utils.mapRange(this.deltaY, 1, 150, 0, 0.8);
        // }
    }

    mouseMove(e) {
        this.deltaX = e.movementX;
        this.scrollLeft -= parseFloat(this.deltaX);
        this.context.scrollLeft = this.scrollLeft;
        if (this.deltaX > 0) {
            this.rate = Utils.mapRange(-this.deltaX, 1, 150, 0, 0.8);
        } else {
            this.rate = Utils.mapRange(-this.deltaX, 1, 150, 0, 3);
        }
        // console.log(rate)
        // this.context.scrollLeft += this.deltaY;
    }

    scrollUpdate() {
        if (!this.disableScroll) {
            this.scrollPos = this.getScrollPos();
            if (this.clonesWidth + this.scrollPos >= this.scrollWidth) {
                // console.log('arriving at the end');
                this.setScrollPos(1);
                // this.context.style.overflowX = 'hidden';
                this.disableScroll = true;
                // console.log('loop');

                this.shuffleOrder();
            } else if (this.scrollPos <= 0) {
                this.setScrollPos(this.scrollWidth - this.clonesWidth);
                // this.context.style.overflowX = 'hidden';
                this.disableScroll = true;
            }
        }

        if (this.disableScroll) {
            setTimeout(() => {
                this.disableScroll = false;
                // this.context.style.overflowX = 'scroll';
            }, 40);
        }
    }

    init() {
        requestAnimationFrame(this.reCalc.bind(this));

        setInterval(() => {
            requestAnimationFrame(this.reCalc.bind(this));
        }, 500);

        this.images = document.querySelectorAll('.nl-img:not(.is-clone)');
        this.temp = [];

        for (let i = 0; i < this.images.length; i++) {
            this.temp.push(this.images[i].cloneNode(false));
        }

        function buildThresholdList(numSteps) {
            var thresholds = [];

            for (var i = 1.0; i <= numSteps; i++) {
                var ratio = i / numSteps;
                thresholds.push(ratio);
            }

            thresholds.push(0);
            return thresholds;
        }

        this.options = {
            root: null,
            rootMargin: '0px',
            threshold: buildThresholdList(10),
        };

        this.observer = new IntersectionObserver(
            this.observeCallback.bind(this),
            this.options,
        );
        // this.observer.observe(this.images);
        this.images.forEach((image) => {
            this.observer.observe(image);
        });

        let supportsPassive = false;
        try {
            let opts = Object.defineProperty({}, 'passive', {
                get: function () {
                    supportsPassive = true;
                },
            });
            window.addEventListener('testPassive', null, opts);
            window.removeEventListener('testPassive', null, opts);
        } catch (e) {}

        let timeout;
        let isScrolling;

        this.context.addEventListener(
            'wheel',
            (e) => {
                // requestAnimationFrame(this.mouseWheel.bind(this, e));

                if (timeout) {
                    cancelAnimationFrame(timeout);
                }

                timeout = requestAnimationFrame(this.mouseWheel.bind(this, e));

                this.disableAutomatic = true;
                clearTimeout(isScrolling);
                isScrolling = setTimeout(() => {
                    // Run the callback
                    // console.log('Scrolling has stopped.');
                    setTimeout(() => {
                        // this.sound.rate(1);
                        this.disableAutomatic = false;
                    }, 400);
                }, 100);
            },
            supportsPassive ? { passive: true } : false,
        );

        this.context.addEventListener(
            'touchmove',
            (e) => {
                // requestAnimationFrame(this.mouseWheel.bind(this, e));

                if (timeout) {
                    cancelAnimationFrame(timeout);
                }

                timeout = requestAnimationFrame(this.touchMove.bind(this, e));
            },
            supportsPassive ? { passive: true } : false,
        );

        let timeoutS;

        this.context.addEventListener(
            'scroll',
            (e) => {
                if (timeoutS) {
                    cancelAnimationFrame(timeoutS);
                }
                // this.scrollUpdate();
                timeoutS = requestAnimationFrame(this.scrollUpdate.bind(this));
            },
            supportsPassive ? { passive: true } : false,
        );

        this.context.addEventListener('touchstart', (e) => {
            clearTimeout(isScrolling);
            this.disableAutomatic = true;
            this.mouseDown = true;
            this.mouseStill = true;
            this.rate = -2;
        });

        this.context.addEventListener('touchend', (e) => {
            this.mouseDown = false;
            this.mouseStill = false;
            isScrolling = setTimeout(() => {
                // Run the callback
                // console.log('Scrolling has stopped.');

                // this.sound.rate(1);
                this.scrollLeft = this.context.scrollLeft;
                this.disableAutomatic = false;
            }, 2000);
        });

        this.context.addEventListener('mousedown', (e) => {
            this.disableAutomatic = true;
            this.mouseDown = true;
            this.mouseStill = true;
            this.rate = -2;
        });

        let timeoutM;
        let isMouseMoving;

        this.context.addEventListener('mousemove', (e) => {
            if (timeoutM) {
                cancelAnimationFrame(timeoutM);
            }
            if (this.mouseDown) {
                timeoutM = requestAnimationFrame(this.mouseMove.bind(this, e));
            }

            clearTimeout(isScrolling);
            isMouseMoving = setTimeout(() => {
                // Run the callback
                // console.log('Scrolling has stopped.');
                setTimeout(() => {
                    // this.sound.rate(1);
                    if (this.mouseDown) {
                        this.mouseStill = true;
                        this.rate = -2;
                    }
                }, 400);
            }, 100);
        });

        this.context.addEventListener('mouseup', (e) => {
            this.disableAutomatic = false;
            this.mouseDown = false;
            this.mouseStill = false;
        });

        // let mouseTimeout;

        // for (let i = 0; i < this.labels.length; i++) {
        // 	this.labels[i].addEventListener('mouseover', () => {
        // 		clearTimeout(mouseTimeout);
        // 		this.disableAutomatic = true;
        // 	});
        // 	this.labels[i].addEventListener('mouseleave', () => {
        // 		mouseTimeout = setTimeout(() => {
        // 			this.disableAutomatic = false;
        // 		}, 500);
        // 	});
        // }

        this.context.addEventListener('mouseenter', (e) => {
            this.mouseover = true;
        });
        this.context.addEventListener('mouseleave', (e) => {
            this.mouseover = false;
        });

        this.toAbout.addEventListener('click', (e) => {
            console.log(
                this.context.scrollLeft,
                this.credits.getBoundingClientRect().left,
            );
            // this.scrollLeft = this.credits.getBoundingClientRect().left;
            this.scrollLeft = this.lineWidth + window.innerWidth + 150;
            this.context.scrollLeft = this.scrollLeft;
        });

        // this.sliderRect = this.speedSlider.getBoundingClientRect();
        // this.textRect = this.speedText.getBoundingClientRect();

        // 			let pxValue = Utils.mapRange(
        // 				this.speedSlider.value,
        // 				this.speedSlider.min,
        // 				this.speedSlider.max,
        // 				0,
        // 				this.sliderRect.width - this.textRect.width
        // 			);
        // 	this.speedText.style.left = pxValue + 'px';

        // this.speedSlider.addEventListener('input', (e) => {
        // 	// console.log(e.target.value)
        // 	this.scrollingSpeed = e.target.value
        // 	let pxValue = Utils.mapRange(this.scrollingSpeed, this.speedSlider.min, this.speedSlider.max, 0, this.sliderRect.width - this.textRect.width)
        // 	this.speedText.style.left = pxValue + 'px';
        // })
    }

    animate() {
        this.frame++;
        // if (!this.disableScroll && !this.disableAutomatic && this.frame % 2 == 0) {

        if (!this.disableScroll && !this.disableAutomatic) {
            // if (this.mouseover) {
            // 	this.scrollLeft -= parseFloat(this.context.dataset.speed);
            // 	this.scrollLeft -= parseFloat(this.context.dataset.speed);
            // 	this.context.scrollLeft = this.scrollLeft;
            // } else {
            this.scrollLeft += parseFloat(this.context.dataset.speed);
            // this.scrollLeft += parseFloat(this.scrollingSpeed);
            // this.scrollLeft += 2;
            // console.log(Math.floor(this.scrollLeft));
            this.context.scrollLeft = Math.floor(this.scrollLeft);
            // this.context.scrollLeft += 6;

            // this.context.scrollLeft += parseFloat(this.context.dataset.speed);
            // this.context.scrollLeft += parseFloat(this.context.dataset.speed);
            // }
        }

        let targetRate = 0;
        if (!this.mouseStill) {
            let da = targetRate - this.rate;
            this.rate += da * 0.1;
            // if (this.rate > 0) {
            // 	this.rate -= 0.03;
            // } else if (this.rate < 0) {
            // 	this.rate += 0.03;
            // }
        }

        let finalRate = 1 + this.rate;

        finalRate = Math.max(finalRate, 0.05);

        this.audio.animate(finalRate);
    }

    observeCallback(entries, observer) {
        entries.forEach((entry) => {
            // chaque élément de entries correspond à une variation
            // d'intersection pour un des éléments cible:
            //   entry.boundingClientRect
            //   entry.intersectionRatio
            //   entry.intersectionRect
            //   entry.isIntersecting
            //   entry.rootBounds
            //   entry.target
            //   entry.time
            if (entry.isIntersecting) {
                // console.log(entry.target.src.split('/').pop(), entry.target.dataset.src.split('/').pop());
                // console.log(entry.target.nextElementSibling.src.split('/').pop(), entry.target.nextElementSibling.dataset.src.split('/').pop());
                // console.log(entry.target.nextElementSibling.nextElementSibling.src.split('/').pop(), entry.target.nextElementSibling.nextElementSibling.dataset.src.split('/').pop());
                // console.log(entry.target.nextElementSibling.nextElementSibling.nextElementSibling.src.split('/').pop(), entry.target.nextElementSibling.nextElementSibling.nextElementSibling.dataset.src.split('/').pop());
                if (
                    entry.target.src.split('/').pop() !=
                    entry.target.dataset.src.split('/').pop()
                )
                    entry.target.src = entry.target.dataset.src;

                if (entry.target.nextElementSibling) {
                    if (
                        entry.target.nextElementSibling.src.split('/').pop() !=
                        entry.target.nextElementSibling.dataset.src
                            .split('/')
                            .pop()
                    )
                        entry.target.nextElementSibling.src =
                            entry.target.nextElementSibling.dataset.src;
                    if (entry.target.nextElementSibling.nextElementSibling) {
                        if (
                            entry.target.nextElementSibling.nextElementSibling.src
                                .split('/')
                                .pop() !=
                            entry.target.nextElementSibling.nextElementSibling.dataset.src
                                .split('/')
                                .pop()
                        )
                            entry.target.nextElementSibling.nextElementSibling.src =
                                entry.target.nextElementSibling.nextElementSibling.dataset.src;
                        if (
                            entry.target.nextElementSibling.nextElementSibling
                                .nextElementSibling
                        ) {
                            if (
                                entry.target.nextElementSibling.nextElementSibling.nextElementSibling.src
                                    .split('/')
                                    .pop() !=
                                entry.target.nextElementSibling.nextElementSibling.nextElementSibling.dataset.src
                                    .split('/')
                                    .pop()
                            )
                                entry.target.nextElementSibling.nextElementSibling.nextElementSibling.src =
                                    entry.target.nextElementSibling.nextElementSibling.nextElementSibling.dataset.src;
                            if (
                                entry.target.nextElementSibling
                                    .nextElementSibling.nextElementSibling
                                    .nextElementSibling
                            ) {
                                if (
                                    entry.target.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.src
                                        .split('/')
                                        .pop() !=
                                    entry.target.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.dataset.src
                                        .split('/')
                                        .pop()
                                )
                                    entry.target.nextElementSibling.nextElementSibling.nextElementSibling.src =
                                        entry.target.nextElementSibling.nextElementSibling.nextElementSibling.dataset.src;
                            }
                        }
                    }
                }

                if (entry.target.previousElementSibling) {
                    if (
                        entry.target.previousElementSibling.src
                            .split('/')
                            .pop() !=
                        entry.target.previousElementSibling.dataset.src
                            .split('/')
                            .pop()
                    )
                        entry.target.previousElementSibling.src =
                            entry.target.previousElementSibling.dataset.src;
                    if (
                        entry.target.previousElementSibling
                            .previousElementSibling
                    ) {
                        if (
                            entry.target.previousElementSibling.previousElementSibling.src
                                .split('/')
                                .pop() !=
                            entry.target.previousElementSibling.previousElementSibling.dataset.src
                                .split('/')
                                .pop()
                        )
                            entry.target.previousElementSibling.previousElementSibling.src =
                                entry.target.previousElementSibling.previousElementSibling.dataset.src;
                        if (
                            entry.target.previousElementSibling
                                .previousElementSibling.previousElementSibling
                        ) {
                            if (
                                entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src
                                    .split('/')
                                    .pop() !=
                                entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src
                                    .split('/')
                                    .pop()
                            )
                                entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src =
                                    entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src;
                            if (
                                entry.target.previousElementSibling
                                    .previousElementSibling
                                    .previousElementSibling
                                    .previousElementSibling
                            ) {
                                if (
                                    entry.target.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.src
                                        .split('/')
                                        .pop() !=
                                    entry.target.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src
                                        .split('/')
                                        .pop()
                                )
                                    entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src =
                                        entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src;
                            }
                        }
                    }
                }
                // if (entry.target.previousElementSibling.src.split('/').pop() != entry.target.previousElementSibling.dataset.src.split('/').pop()) entry.target.previousElementSibling.src = entry.target.previousElementSibling.dataset.src;
                // if (entry.target.previousElementSibling.previousElementSibling.src.split('/').pop() != entry.target.previousElementSibling.previousElementSibling.dataset.src.split('/').pop()) entry.target.previousElementSibling.previousElementSibling.src = entry.target.previousElementSibling.previousElementSibling.dataset.src;
                // if (entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src.split('/').pop() != entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src.split('/').pop()) entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src = entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src;
                // if (entry.target.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.src.split('/').pop() != entry.target.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src.split('/').pop()) entry.target.previousElementSibling.previousElementSibling.previousElementSibling.src = entry.target.previousElementSibling.previousElementSibling.previousElementSibling.dataset.src;
                // console.log(entry.target);
                observer.unobserve(entry.target);
            }
        });
    }

    shuffleOrder() {
        // for (let i = images.length + 1; i--;) {
        // }

        Utils.shuffle(this.temp);

        // let clones = temp.slice(0, 5);
        // let newClones = '';
        // clones.forEach((el) => {
        // 	let cl = el.cloneNode(true);
        // 	cl.classList.add('is-clone');
        // 	newClones += cl.outerHTML;
        // });

        let newTemp = '';
        this.temp.forEach((el) => {
            newTemp += el.outerHTML;
        });

        let newOrder = '';
        newOrder += this.siteTitle.outerHTML;
        newOrder +=
            '<div id="lineContainer" class="lineContainer" style="width: ' +
            this.lineWidth +
            'px;">';
        newOrder += newTemp;
        newOrder += '</div>';
        newOrder += this.credits.outerHTML;
        newOrder += this.siteTitleClone.outerHTML;
        // newOrder += newClones;

        this.container.innerHTML = newOrder;

        this.images = document.querySelectorAll('.nl-img:not(.is-clone)');

        // this.siteTitleClone = document.querySelector('h1.is-clone');
        this.images.forEach((image) => {
            this.observer.observe(image);
        });

        setTimeout(() => {
            this.clones = this.context.querySelectorAll('.is-clone');
            requestAnimationFrame(this.reCalc.bind(this));
        }, 200);
    }

    onWindewResize() {
        requestAnimationFrame(this.reCalc.bind(this));
    }
}
