const Utils = {
    // Converts from degrees to radians.
    toRadians: function (degrees) {
        return (degrees * Math.PI) / 180;
    },

    // Converts from radians to degrees.
    toDegrees: function (radians) {
        return (radians * 180) / Math.PI;
    },

    mapRange: function (value, low1, high1, low2, high2) {
        return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
    },

    shuffle: function (array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    },
};

export default Utils;
